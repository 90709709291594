import axios from './axios'

export const list = params => axios.get('/management/member', { params })
export const getSubordinate = (id, params) => axios.get(`/management/member/sub/${id}`, { params })
export const getOrderRecord = (params) => axios.get('/management/member/order', { params })
export const exportData = (params) => axios.get('/management/member/export-param', { params })
export const edit = (form) => axios.put('/management/member', form)
// 加入黑名单
export const black = (id) => axios.put(`/management/member/${id}/black`)
// 解除黑名单
export const unblack = (id) => axios.put(`/management/member/${id}/unblack`)
