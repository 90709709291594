<template>
  <el-dialog
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="cancel"
  >
    <div class="title" slot="title">
      <span>【消费记录】</span>
      <img :src="info.imgUrl" style="" />
      <span>{{ info.nickName }}</span>
      <span>{{ info.phoneNumber ? info.phoneNumber.slice(-11) : "" }}</span>
      <span>上级: {{parentLevel.nickName}}</span>
    </div>
    <el-table ref="multipleTable" border :data="records" style="width: 100%">
      <el-table-column prop="goodsList" width="300" border label="商品信息">
        <template slot-scope="scope">
          <div
            class="product"
            v-for="(item, index) in scope.row.goodsList"
            :key="item.goodsId"
            :style="{
              borderBottom:
                index === scope.row.goodsList.length - 1
                  ? 'none'
                  : '1px solid #D9E0F0'
            }"
          >
            <img :src="item.mainImgUrl" alt="..." />
            <div class="product-info">
              <div>{{ item.goodsName }}</div>
              <div>
                <span>规格：{{ item.skuName }}</span>
                <span>数量：{{ item.quantity }}</span>
              </div>
              <div>
                <span class="product-count"
                  >小计：￥{{ _pennyToYuan(item.amount) }}</span
                >
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="订单编号"> </el-table-column>
      <el-table-column prop="goodsAmount" label="总金额">
        <template slot-scope="scope">
          ¥{{ _pennyToYuan(scope.row.goodsAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="下单时间"> </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import axios from '@/api/axios'
import * as memberService from '@/api/member'

export default {
  naem: 'editRole',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    info: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      total: 10,
      pageSize: 10,
      currentPage: 1,
      records: [],
      parentLevel: {},
      memberTypeMap: {
        0: '普通用户',
        1: '合伙人',
        2: '金牌合伙人'
      }
    }
  },
  methods: {
    async fetchRecord (
      recordId = this.info.id,
      page = this.currentPage,
      size = this.pageSize
    ) {
      const query = {
        id: recordId,
        pageNo: page - 1,
        pageSize: size
      }
      try {
        const res = await memberService.getOrderRecord(query)
        this.records = res.records
        this.total = res.total
      } catch (err) {}
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchRecord()
    },
    handleSizeChange (val) {
      this.currentPage = 1
      this.pageSize = val
      this.fetchRecord()
    },
    cancel () {
      this.$emit('onCancel')
    },
    async getParent () {
      const params = {
        pageNo: 0,
        pageSize: 20
      }
      const res = await axios.get(`/management/member/parent/${this.info.id}`, { params })
      this.parentLevel = Array.isArray(res.records) && res.records.length > 0 ? res.records[0] : {}
    }
  },
  watch: {
    visible: {
      deep: true,
      handler: async function (curVal, oldVal) {
        if (curVal) {
          this.fetchRecord()
          this.getParent()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog__body {
  height: calc(60vh);
  overflow-y: auto;
}
.title {
  color: #7d7e8e;
  img {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    margin-right: 10px;
    position: relative;
    top: 7px;
  }
  span {
    margin-right: 10px;
  }
}
.footer {
  border-top: 1px solid #d9e0f0ff;
  padding-top: 30px;
  text-align: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}

.product {
  display: grid;
  padding: 20px;
  grid-template-columns: 80px auto;
  grid-gap: 10px;
  border-bottom: 1px solid #d9e0f0;
  > img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: center;
  }

  &-count {
    height: 16px;
    font-size: 12px;
    font-weight: bold;
    color: #5a5b66;
    line-height: 16px;
  }
}
</style>
