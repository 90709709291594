<template>
  <div class="container">
    <div class="search">
      <div class="search-first">
        <div class="search-item">
          <div class="search-item-name">客户昵称：</div>
          <el-input
            placeholder="请输入客户昵称"
            prefix-icon="el-icon-search"
            v-model="search.name"
            @keydown.native.enter="handleSearch"
          >
          </el-input>
        </div>
        <div class="search-item">
          <div class="search-item-name">手机号码：</div>
          <el-input
            placeholder="请输入手机号码"
            prefix-icon="el-icon-search"
            v-model="search.phone"
            @keydown.native.enter="handleSearch"
          >
          </el-input>
        </div>
        <div class="search-item">
          <div class="search-item-name">会员身份：</div>
          <el-select v-model="search.memberLevel" placeholder="请选择会员身份" clearable @change="handleSearch">
            <el-option label="非会员" :value="0"> </el-option>
            <el-option label="普通会员" :value="1"> </el-option>
            <el-option label="超级会员" :value="2"> </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="search-item-name">合伙人身份：</div>
          <el-select v-model="search.memberType" placeholder="请选择客户身份" clearable @change="handleSearch">
            <el-option label="普通用户" :value="0"> </el-option>
            <el-option label="合伙人" :value="1"> </el-option>
            <el-option label="金牌合伙人" :value="2"> </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="search-item-name">订单金额：</div>
          <div style="display: flex, align-items: center">
            <el-input-number
              v-model="search.minOrderAmount"
              :controls="false"
              @blur="handleSearch"
              style="width: 40%"
            ></el-input-number>
            <span> - </span>
            <el-input-number
              v-model="search.maxOrderAmount"
              :controls="false"
              @blur="handleSearch"
              style="width: 40%"
            ></el-input-number>
          </div>
        </div>
      </div>
      <div class="search-second">
        <div class="search-item">
          <div class="search-item-name">订单数量：</div>
          <div style="display: flex, align-items: center">
            <el-input-number
              v-model="search.minOrderCount"
              :controls="false"
              @blur="handleSearch"
              style="width: 40%"
            ></el-input-number>
            <span> - </span>
            <el-input-number
              v-model="search.maxOrderCount"
              :controls="false"
              @blur="handleSearch"
              style="width: 40%"
            ></el-input-number>
          </div>
        </div>
        <div class="search-item">
          <div class="search-item-name">注册时间：</div>
          <el-date-picker
            clearable
            v-model="search.range"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleSearch"
          >
          </el-date-picker>
        </div>
        <div>
          <el-button type="primary" plain @click="reset">重置</el-button>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        </div>
      </div>
    </div>

    <div class="content">
      <el-table ref="multipleTable" :data="members" style="width: 100%">
        <el-table-column prop="nickName" width="140" label="基本信息">
          <template slot-scope="scope">
            <div class="basic">
              <img :src="scope.row.imgUrl" />
              <div>
                <div>{{ scope.row.nickName }}</div>
                <div>ID：{{ scope.row.id }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="电话">
          <template v-slot="scope">
            {{ scope.row.phoneNumber ? scope.row.phoneNumber.slice(-11) : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="memberType" label="会员身份">
          <template slot-scope="scope">
            <p>{{ memberLevelMap[scope.row.memberLevel] }}</p>
            <p>{{ scope.row.memberExpireTime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="memberType" label="合伙人身份">
          <template slot-scope="scope">
            <p>{{ memberTypeMap[scope.row.memberType] }}</p>
            <p>{{ scope.row.expireTime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="balance" label="钱包金额">
          <template slot-scope="scope"> ¥{{ _pennyToYuan(scope.row.balance) }} </template>
        </el-table-column>
        <el-table-column prop="award" label="佣金金额">
          <template slot-scope="scope"> ¥{{ _pennyToYuan(scope.row.award) }} </template>
        </el-table-column>
        <el-table-column prop="orderCount">
          <template v-slot:header>
            <span>订单数量</span>
            <i
              v-if="orderByValue !== 'e.order_count'"
              @click="changeOrder(1)"
              class="el-icon-d-caret"
              style="cursor: pointer;"
            ></i>
            <i
              v-if="orderByValue === 'e.order_count' && ascValue === 'desc'"
              @click="changeOrder(2)"
              class="el-icon-caret-bottom"
              style="cursor: pointer; color: #5cb6ff;"
            ></i>
            <i
              v-if="orderByValue === 'e.order_count' && ascValue === 'asc'"
              @click="changeOrder(3)"
              class="el-icon-caret-top"
              style="cursor: pointer; color: #5cb6ff;"
            ></i>
          </template>
        </el-table-column>
        <el-table-column>
          <template v-slot:header>
            <span>订单金额</span>
            <i
              v-if="orderByValue !== 'e.order_amount'"
              @click="changeOrder(4)"
              class="el-icon-d-caret"
              style="cursor: pointer;"
            ></i>
            <i
              v-if="orderByValue === 'e.order_amount' && ascValue === 'desc'"
              @click="changeOrder(5)"
              class="el-icon-caret-bottom"
              style="cursor: pointer; color: #5cb6ff;"
            ></i>
            <i
              v-if="orderByValue === 'e.order_amount' && ascValue === 'asc'"
              @click="changeOrder(6)"
              class="el-icon-caret-top"
              style="cursor: pointer; color: #5cb6ff;"
            ></i>
          </template>
          <template slot-scope="scope"> ¥{{ _pennyToYuan(scope.row.orderAmount) }} </template>
        </el-table-column>
        <el-table-column prop="latestOrderAmount" label="最近消费">
          <template slot-scope="scope">
            <div v-show="scope.row.latestOrderAmount">
              <div>¥{{ _pennyToYuan(scope.row.latestOrderAmount) }}</div>
              <div>{{ scope.row.latestOrderDate }}</div>
            </div>
            <div v-show="!scope.row.latestOrderAmount">暂无</div>
          </template>
        </el-table-column>
        <el-table-column prop="subMemberCount" label="下级人数"> </el-table-column>
        <el-table-column label="下级完成订单金额">
          <template slot-scope="scope"> ￥{{ _pennyToYuan(scope.row.subOrderAmount) }} </template>
        </el-table-column>
        <el-table-column prop="createDate" label="创建日期"> </el-table-column>
        <el-table-column align="center" prop="operation" min-width="100" label="操作">
          <template slot-scope="scope">
            <el-button v-if="canEdit" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="checkParent(scope.row.id)">上级</el-button>
            <el-button type="text" @click="checkSub(scope.row)">下级</el-button>
            <el-button type="text" @click="changeRelation(scope.row.id)">指定上下级</el-button>
            <el-button type="text" @click="record(scope.row)">消费记录</el-button>
            <el-button type="text" @click="black(scope.row)">{{ scope.row.black ? "解除黑名单" : "加入黑名单" }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 此部分先隐藏，暂时没说需要 -->
      <!-- <div style="margin-top: 10px; display: flex; justify-content: flex-end;">
        <el-button type="primary" plain @click="exportData">导出</el-button>
      </div> -->
    </div>

    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageOptions"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <EditContent :visible="visible" :data="selectData" @onConfirmEdit="handleConfirmEdit" @onCancel="visible = false" />

    <Subordinate
      :visible="subVisible"
      :data="subordinateId"
      @onConfirmEdit="subVisible = true"
      @onCancel="subVisible = false"
      @subRecord="record"
    />

    <Record :visible="recordVisible" :info="recordInfo" @onConfirmEdit="recordVisible = true" @onCancel="recordVisible = false" />

    <ParentLevel v-if="showParent" :id="currentId" @cancel="showParent = false"></ParentLevel>

    <Relation v-if="showRelation" :id="currentId" @cancel="showRelation = false"></Relation>
  </div>
</template>

<script>
import * as R from 'ramda'
import { pageOptions } from '@/utils/config'
import * as memberService from '@/api/member'
import moment from 'moment'
import EditContent from './edit'
import Subordinate from './subordinate'
import ParentLevel from './parentLevel.vue'
import Record from './record'
import Relation from './relation.vue'
import { errorMessage } from '@/utils/validator'

const init = {
  name: null,
  phone: null,
  memberType: null,
  memberLevel: null,
  minOrderAmount: undefined,
  maxOrderAmount: undefined,
  minOrderCount: undefined,
  maxOrderCount: undefined,
  active: null,
  range: ['', '']
}

export default {
  components: { EditContent, Subordinate, Record, ParentLevel, Relation },
  data () {
    return {
      search: R.clone(init),
      type: 'create',
      members: [],
      visible: false,
      subVisible: false,
      subordinateId: null,
      recordVisible: false,
      recordInfo: {},
      pageOptions: pageOptions,
      total: 0,
      pageSize: 10,
      currentPage: 1,
      showEdit: false,
      selectData: null,
      memberTypeMap: {
        0: '非合伙人',
        1: '普通合伙人',
        2: '金牌合伙人'
      },
      memberLevelMap: {
        0: '非会员',
        1: '普通会员',
        2: '超级会员'
      },
      showParent: false,
      currentId: '',
      orderByValue: '',
      ascValue: '',
      showRelation: false // 显示指定上下级弹框
    }
  },
  computed: {
    canEdit () {
      return this.$store.state?.userInfo?.roleList?.includes(13)
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fmtDate (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    fmt (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    async fetchData (page = this.currentPage, size = this.pageSize, search = this.search) {
      try {
        const query = search
          ? {
            name: search.name,
            phone: search.phone,
            memberType: search.memberType,
            memberLevel: search.memberLevel,
            active: search.active,
            minOrderAmount: this._yuanToPenny(search.minOrderAmount) || null,
            maxOrderAmount: this._yuanToPenny(search.maxOrderAmount) || null,
            minOrderCount: search.minOrderCount || null,
            maxOrderCount: search.maxOrderCount || null,
            startDate: search.range && search.range[0] ? this.fmt(search.range[0]) : null,
            endDate: search.range && search.range[1] ? this.fmt(search.range[1]) : null,
            orderByValue: this.orderByValue, // e.order_amount  e.order_count
            ascValue: this.ascValue,
            pageNo: page - 1,
            pageSize: size
          }
          : {
            pageNo: page - 1,
            pageSize: size
          }
        const res = await memberService.list(query)
        this.members = res.records
        this.total = res.total
      } catch (err) {
        // handle error
      }
    },
    async handleConfirmEdit (data) {
      const postData = {
        id: data.id,
        memberType: data.memberType,
        expireTime: this.fmt(data.expireTime),
        memberLevel: data.memberLevel,
        memberExpireTime: this.fmt(data.memberExpireTime)
      }
      try {
        await memberService.edit(postData)
        this.fetchData()
        this.$notify.success('保存成功！')
      } catch (err) {
        const message = errorMessage(err) || '保存失败！'
        this.$notify.error(message)
      }
    },
    isAdmin (name) {
      return R.contains('admin', R.toLower(name))
    },
    handleSearch () {
      this.currentPage = 1
      this.fetchData()
    },
    edit (data) {
      this.selectData = R.clone(data)
      this.visible = true
    },
    checkSub (data) {
      this.subordinateId = data.id
      this.subVisible = true
    },
    record (data) {
      this.recordInfo = data
      this.recordVisible = true
    },
    checkParent (id) {
      this.currentId = id
      this.showParent = true
    },
    changeRelation (id) {
      this.currentId = id
      this.showRelation = true
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchData()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData()
    },
    reset () {
      this.search = R.clone(init)
      this.currentPage = 1
      this.fetchData()
    },
    async exportData () {
      const query = this.search
        ? {
          name: this.search.name,
          phone: this.search.phone,
          memberType: this.search.memberType,
          active: this.search.active,
          minOrderAmount: this.search.minOrderAmount,
          maxOrderAmount: this.search.maxOrderAmount,
          minOrderCount: this.search.minOrderCount,
          maxOrderCount: this.search.maxOrderCount,
          startDate: this.fmtDate(this.search.range[0]),
          endDate: this.fmtDate(this.search.range[1])
        }
        : {}
      await memberService.exportData(query)
    },
    changeOrder (num) {
      switch (num) {
        case 1:
          this.orderByValue = 'e.order_count'
          this.ascValue = 'desc'
          break
        case 2:
          this.orderByValue = 'e.order_count'
          this.ascValue = 'asc'
          break
        case 3:
          this.orderByValue = ''
          this.ascValue = ''
          break
        case 4:
          this.orderByValue = 'e.order_amount'
          this.ascValue = 'desc'
          break
        case 5:
          this.orderByValue = 'e.order_amount'
          this.ascValue = 'asc'
          break
        case 6:
          this.orderByValue = ''
          this.ascValue = ''
          break
      }
      this.fetchData()
    },
    async black (obj) {
      try {
        if (obj.black === 0) {
          await memberService.black(obj.id)
        } else {
          await memberService.unblack(obj.id)
        }
        this.$notify.success('设置成功！')
        this.fetchData()
      } catch (error) {
        const message = errorMessage(error) || '设置失败！'
        this.$notify.error(message)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.content {
  margin-top: 10px;
  background: #ffffff;
  padding: 10px;
}
.pagination {
  margin-top: 30px;
  text-align: center;
}

.search {
  padding: 30px;
  margin: 10px auto;
  width: 100%;
  background: white;

  &-first {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    padding-bottom: 20px;
  }
  &-second {
    border-top: 1px solid #f4f7fc;
    padding-top: 20px;
    display: grid;
    grid-template-columns: 2fr 2fr 3fr;
    grid-column-gap: 20px;
  }
  &-item {
    display: grid;
    grid-template-columns: 100px auto;
    align-items: center;
  }
}

.basic {
  display: grid;
  grid-template-columns: 40px 100px;
  grid-gap: 10px;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
