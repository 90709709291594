<template>
  <el-dialog title="上级" :visible.sync="visible" :append-to-body="true" @close="cancel">
    <el-table :data="dataList" style="width: 100%">
      <el-table-column width="140" border label="用户信息">
        <template slot-scope="scope">
          <div class="basic">
            <img :src="scope.row.imgUrl" />
            <div>
              <div>{{ scope.row.nickName }}</div>
              <div>ID：{{ scope.row.id }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="电话">
        <template v-slot="scope">
            {{scope.row.phoneNumber ? scope.row.phoneNumber.slice(-11) : ''}}
          </template>
      </el-table-column>
      <el-table-column label="身份">
        <template slot-scope="scope">
          {{ memberTypeMap[scope.row.memberType] }}
        </template>
      </el-table-column>
      <el-table-column prop="orderCount" label="订单数量"> </el-table-column>
      <el-table-column label="订单金额">
        <template slot-scope="scope">
          ¥{{ _pennyToYuan(scope.row.orderAmount) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="operation" min-width="100" label="操作">
        <template>
          <el-button type="text" @click="remove">解除绑定</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import axios from '@/api/axios'

export default {
  props: {
    id: {
      type: Number,
      require: true
    }
  },
  data () {
    return {
      visible: true,
      dataList: [],
      pageSize: 10,
      pageNo: 0,
      memberTypeMap: {
        0: '普通用户',
        1: '合伙人',
        2: '金牌合伙人'
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      const res = await axios.get(`/management/member/parent/${this.id}`, { params })
      this.dataList = res.records
    },
    remove () {
      this.$confirm('确认解除绑定?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await axios.put(`/management/member/${this.id}/unbind?type=1`)
          this.$notify.success('解绑成功！')
          this.init()
        } catch (error) {
          this.$notify.error('解绑失败！')
        }
      }).catch(() => {})
    },
    cancel () {
      this.$emit('cancel')
    }
  }

}
</script>

<style lang="scss" scoped>
.basic {
  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: 10px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
