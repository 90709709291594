<template>
  <el-dialog title="下级" width="70%" top="10vh" :visible.sync="visible" :append-to-body="true" :before-close="cancel">
    <el-table ref="multipleTable" :data="subordinates" style="width: 100%">
      <el-table-column width="200" border label="用户信息">
        <template slot-scope="scope">
          <div class="basic">
            <img :src="scope.row.imgUrl" />
            <div>
              <div>{{ scope.row.nickName }}</div>
              <div>ID：{{ scope.row.id }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="电话">
        <template v-slot="scope">
          {{ scope.row.phoneNumber ? scope.row.phoneNumber.slice(-11) : '' }}
        </template>
      </el-table-column>
      <el-table-column label="身份">
        <template slot-scope="scope">
          {{ memberTypeMap[scope.row.memberType] }}
        </template>
      </el-table-column>
      <el-table-column align="right" prop="orderCount" label="订单数量"> </el-table-column>
      <el-table-column align="right" label="订单金额">
        <template slot-scope="scope"> ¥{{ _pennyToYuan(scope.row.orderAmount) }} </template>
      </el-table-column>
      <el-table-column align="right" prop="latestOrderAmount" label="最近消费">
        <template slot-scope="scope">
          <div v-show="scope.row.latestOrderAmount">
            <div>¥{{ _pennyToYuan(scope.row.latestOrderAmount) }}</div>
            <div>{{ scope.row.latestOrderDate }}</div>
          </div>
          <div v-show="!scope.row.latestOrderAmount">暂无</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="operation" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="record(scope.row)">消费记录</el-button>
          <el-button type="text" @click="remove(scope.row.id)">解除绑定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import * as memberService from '@/api/member'
import axios from '@/api/axios'

export default {
  naem: 'editRole',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    data: Number
  },
  data () {
    return {
      total: 10,
      pageSize: 10,
      currentPage: 1,
      subordinates: [],
      memberTypeMap: {
        0: '普通用户',
        1: '合伙人',
        2: '金牌合伙人'
      }
    }
  },
  watch: {
    visible: {
      deep: true,
      handler: async function (curVal, oldVal) {
        if (curVal) {
          this.currentPage = 1
          this.fetchData()
        }
      }
    }
  },
  methods: {
    async fetchData (id = this.data, page = this.currentPage, size = this.pageSize) {
      const query = {
        pageNo: page - 1,
        pageSize: size
      }
      try {
        const res = await memberService.getSubordinate(id, query)
        this.subordinates = res.records
        this.total = res.total
      } catch (err) {}
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData()
    },
    record (obj) {
      this.$emit('subRecord', obj)
    },
    remove (id) {
      this.$confirm('确认解除绑定?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            await axios.put(`/management/member/${id}/unbind?type=2`)
            this.$notify.success('解绑成功！')
            this.fetchData()
          } catch (error) {
            this.$notify.error('解绑失败！')
          }
        })
        .catch(() => {})
    },
    cancel () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style scoped lang="scss">
.basic {
  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: 10px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}

.footer {
  border-top: 1px solid #d9e0f0ff;
  padding-top: 30px;
  text-align: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
/deep/ .el-dialog {
  height: 80vh;
  overflow-y: auto;
}
</style>
