<template>
  <el-dialog
    title="编辑"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="cancel"
    width="600px"
  >
    <field label="客户昵称：" :asterisk="true" labelWidth="130px" :validation="validation.name" >
      <el-input v-model="formData.name" placeholder="请输入" disabled></el-input>
    </field>
    <field label="合伙人身份：" :asterisk="true" labelWidth="130px" :validation="validation.memberType">
      <el-select v-model="formData.memberType" placeholder="请选择">
        <el-option label="非合伙人" :value="0" > </el-option>
        <el-option label="普通合伙人" :value="1" > </el-option>
        <el-option label="金牌合伙人" :value="2" > </el-option>
      </el-select>
    </field>
    <field label="合伙人到期时间：" :asterisk="true" labelWidth="130px" :validation="validation.expireTime">
      <el-date-picker
        v-model="formData.expireTime"
        type="date"
        style="width: 100%"
        placeholder="请选择"
      >
      </el-date-picker>
    </field>
    <field label="会员身份：" :asterisk="true" labelWidth="130px" :validation="validation.memberLevel">
      <el-select v-model="formData.memberLevel" placeholder="请选择">
        <el-option label="非会员" :value="0" > </el-option>
        <el-option label="普通会员" :value="1" > </el-option>
        <el-option label="超级会员" :value="2" > </el-option>
      </el-select>
    </field>
    <field label="会员到期时间：" :asterisk="true" labelWidth="130px" :validation="validation.memberExpireTime">
      <el-date-picker
        v-model="formData.memberExpireTime"
        type="date"
        style="width: 100%"
        placeholder="请选择"
      >
      </el-date-picker>
    </field>
    <div class="footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
import * as R from 'ramda'
const init = {
  name: '',
  memberType: 0,
  expireTime: null,
  memberLevel: 0,
  memberExpireTime: null
}

export default {
  naem: 'editRole',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      formData: R.clone(init)
    }
  },
  mixins: [validation({
    rules: {
      memberType: [v.required('请选择合伙人身份')],
      expireTime: [v.required('请选择合伙人到期时间')],
      memberLevel: [v.required('请选择会员身份')],
      memberExpireTime: [v.required('请选择会员到期时间')]
    },
    field: 'formData'
  })],
  methods: {
    cancel () {
      this.formData = R.clone(init)
      this.$emit('onCancel')
    },
    async confirm () {
      await this.$validate()
      this.$emit('onConfirmEdit', R.clone(this.formData))
      this.formData = R.clone(init)
      this.$emit('onCancel')
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function (curVal, oldVal) {
        if (!R.isNil(curVal)) {
          this.formData = {
            id: curVal.id,
            name: curVal.nickName,
            memberType: curVal.memberType,
            expireTime: curVal.expireTime,
            memberLevel: curVal.memberLevel,
            memberExpireTime: curVal.memberExpireTime
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #D9E0F0FF;
  padding-top: 30px;
  text-align: center;
}

</style>
