<template>
  <el-dialog title="指定上下级关系" width="600px" :visible.sync="visible" :append-to-body="true" :before-close="cancel">
    <el-form ref="form" label-position="left" label-width="100px">
      <el-form-item label="指定关系">
        <el-radio-group v-model="type">
          <el-radio :label="1">下级</el-radio>
          <el-radio :label="2">上级</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选择用户">
        <el-select v-model="choosedId" filterable remote placeholder="请输入手机号" :remote-method="remoteMethod">
          <el-option v-for="item in options" :key="item.id" :label="item.nickName" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import axios from '@/api/axios'

export default {
  props: {
    id: {
      type: Number,
      require: true
    }
  },
  data () {
    return {
      visible: true,
      options: [],
      type: 1,
      choosedId: ''
    }
  },
  methods: {
    async remoteMethod (query) {
      const params = {
        pageNo: 0,
        pageSize: 100,
        phone: query
      }
      const res = await axios('/management/member', { params })
      this.options = res.records
    },
    async confirm () {
      if (!this.choosedId) {
        this.$notify.warning('请选择用户！')
        return
      }
      const sonId = this.type === 1 ? this.choosedId : this.id
      const parentId = this.type === 1 ? this.id : this.choosedId
      await axios.put(`/management/member/change?sonId=${sonId}&parentId=${parentId}`)
      this.$notify.success('绑定成功！')
      this.cancel()
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 15px 30px;
}
/deep/ .el-radio-group {
  margin-top: 0 !important;
}
</style>
